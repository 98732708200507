
import React, { Suspense, lazy , useEffect, useState } from 'react';

//https://feathericons.com/?query=star
import AnimationRevealPage from 'helpers/AnimationRevealPage';
import tw from "twin.macro";
import MainHeader from "components/hero/TwoColumnWithVideo.js";

import MainFeature from "components/features/TwoColWithButton.js";
import serverRedundancyIllustrationImageSrc from "images/server_cluster.svg"
import serverSecureIllustrationImageSrc from "images/server_down.svg"

import macHeroScreenshotImageSrc from "images/steps.png";

import blog1 from "images/benifitsofdx.png"
import blog2 from "images/cloudsf.png"
import blog3 from "images/security.png"


import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";


import GetStarted from 'components/cta/GetStarted';
import GetStartedLight from 'components/cta/GetStartedLight';

import appimg from "images/frontofficefeatures.png";
import dashboardimg from "images/doctorsfeatures.png";
import managerfeatures from "images/managerfeatures.png";
import TwoColumnWithImageAndRating from 'components/testimonials/TwoColumnWithImageAndRating';
import TwoColumnWithVideo from 'components/hero/TwoColumnWithVideo.js';
// import ThreeColSimpleWithImage from 'components/blogs/ThreeColSimpleWithImage';
// import Footer from "components/footers/FiveColumnWithInputForm.js";
// import TrackRecordFeature from "components/features/TwoColSingleFeatureWithStats2.js";
// import GetStartedFeature from "components/features/TwoColWithSteps.js";
// import WhyDontrixFeatures from "components/features/ThreeColWithSideImage.js";
// import SliderCard from "components/cards/ThreeColSlider.js";
const SliderCard = lazy(() => import("components/cards/ThreeColSlider.js"));
const Footer = lazy(() => import("components/footers/FiveColumnWithInputForm.js"));
const TrackRecordFeature = lazy(() => import("components/features/TwoColSingleFeatureWithStats2.js"));
const GetStartedFeature = lazy(() => import("components/features/TwoColWithSteps.js"));
const WhyDontrixFeatures = lazy(() => import("components/features/ThreeColWithSideImage.js"));

const ThreeColSimpleWithImage = lazy(() => import('components/blogs/ThreeColSimpleWithImage'));
const blogPosts = [
  {
    imageSrc:
      blog1,
    category: "Practice Management",
    title: "Benefits of All-In-One Dental Practice Manager Platform",
    url: "/web/benefits-of-dontrix"
  },
  {
    imageSrc:
      blog2,
    category: "Efficiency",
    title: "Why Cloud based Software is the way to go?",
    url: "/web/benefits-of-cloud"
  },
  {
    imageSrc:
      blog3,
    category: "Security",
    title: "How Dontrix protects your patient medical data?",
    url: "/web/security"
  }
]

const cards = [
  {
    imageSrc: ShieldIconImage,
    title: "Higly Secure",
    description: "We host Dontrix on top-tier Amazon Web Services (AWS) Data Center which secures your data to a higher degree. Our Top priority is your data security, and we manage it for you"
  },
  {
    imageSrc: SupportIconImage, title: "24/7 Support"
    , description: "We Provide 24/7 chat support for all our customers. Anytime if you have problems we are there for you."
  },
  {
    imageSrc: CustomizeIconImage, title: "Customizable"
    , description: "Chip details, Medicine details, Lab details, Treatment details, Procedure details, Clinic hours & User Roles  are all highly customizable for every clinic"
  },
  {
    imageSrc: ReliableIconImage, title: "Reliable"
    , description: "We run on more reliable cloud platform and our application is build using the latest technologies. And we keep it up to date for you while you focus on your patient"
  },
  {
    imageSrc: FastIconImage, title: "Fast"
    , description: "Dontrix is a light weight browser based application and so it's performance is really good with a good wifi connection"
  },
  {
    imageSrc: SimpleIconImage, title: "Easy"
    , description: "Intelligent Chip Technology makes it easy for creating patients, treatments, procedures and appointments in just few clicks."
  }
];
const stats = [
  {
    key: "Clinics",
    value: "150+"
  },
  {
    key: "Patients",
    value: "10000+"
  },
  {
    key: "Treatments",
    value: "30000+"
  }, {
    key: "Appointments",
    value: "5000+"
  }, {
    key: "Followups",
    value: "7000+"
  }
];

/* Change this according to your needs */
const features = [
  {
    imageSrc: appimg,
    title: "Front Office Features",
    description: "Dontrix provided a big list of features for Front office staffs to maximizes operational efficiency and productivity of your Dental Practice. ",
    primaryButtonUrl: "/web/ffo"
  },
  {
    imageSrc: dashboardimg,
    title: "Clinic Features",
    description: "Doctors get a enriched list of features to improve their dental practice with great digital experience and automations.",
    primaryButtonUrl: "/web/fdr"
  },
  {
    imageSrc: managerfeatures,
    title: "Manager Features",
    description: "Dontrix offers different roles to manage the dental practice with ease. Practice Manager can get a enahances list of features to manage the practice effectively.",
    primaryButtonUrl: "/web/fcm"
  }
]
export default function HomePage() {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  const [title, setTitle] = useState("Dontrix - Cloud Dental Practice");

  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = title;
  }, [title]);
  return (
    <>
      <AnimationRevealPage>
   

        <MainHeader
          description=" We help improve your dental practice management, while you focus on your patient."
          imageDecoratorBlob={true}
          primaryButtonUrl={"https://calendly.com/dontrix/dontrix-demo"}
          primaryButtonText="Book A Demo"
          watchVideoButtonText="Overview"
          watchVideoYoutubeUrl="https://www.youtube.com/embed/Y272KehTmBY"
        />


        <MainFeature
          subheading="Secure"
          heading="State of the Art Security"
          imageSrc={serverSecureIllustrationImageSrc}
          imageAlt="Dontrix runs on secure AWS Cloud infrastructure"
          buttonRounded={false}
          textOnLeft={false}
          description={"Dontrix runs on Amazon Web Services, a most secure infrastructure. So no worries about your data security. Dontrix has built security features, such as audit logs, encrypted in transit using Hypertext Transfer Protocol (HTTP) over Transport Layer Security (TLS), secure coding practice, encrypted-at-rest  and strong password requirements to ensure application level security. Learn more about AWS "}
          primaryButtonUrl="https://aws.amazon.com/compliance/data-center/data-centers/"
          primaryButtonText="Learn More on AWS Security"
        />
        <MainFeature
          subheading="Reliable"
          heading="Highly Redundant Servers With Backup"
          imageAlt="Highly Redundant Servers With Backup"
          imageSrc={serverRedundancyIllustrationImageSrc}
          buttonRounded={false}
          description = "Dontrix back-up data on every day and the back-ups are stored in multiple regions to ensure it is safe & secure. The reliability of these backups are tested often"
          primaryButtonUrl={"https://calendly.com/dontrix/dontrix-demo"}
          primaryButtonText="Book A Demo"
        />
        <Suspense fallback="...">
          <WhyDontrixFeatures
            cards={cards}
            description="6 Reason to choose Dontrix"
            subheading={<Subheading></Subheading>}
            heading={
              <>
                Why Choose <HighlightedText>Dontrix</HighlightedText>
              </>
            }
          />
          <TwoColumnWithImageAndRating/>
          <SliderCard title="Power of Dontrix" cards={features} />

          <GetStarted pushDownFooter={false} text="Are you ready?, Get Started to enhance your automated patient engagement with Dontrix?" secondaryLinkUrl="/web/contact" />

          < TrackRecordFeature description="We have been developing Dental solution for long 
     time using Visual Basic, Power builder and many more technologies and 
     ended up with lot of maintenance overheads and deprecated technologies and finally 
     on 2020 we started building a system which is more reliable and sustainable for a long run based on Cloud Architecture."
            primaryButtonText="Book A Demo" primaryButtonUrl={"https://calendly.com/dontrix/dontrix-demo"}
            statistics={stats} />
          <GetStartedFeature

            subheading={<Subheading>STEPS</Subheading>}
            heading={
              <>
                Easy to <HighlightedText>Start</HighlightedText>
              </>
            }
            textOnLeft={true}
            imageSrc={macHeroScreenshotImageSrc}
            imageDecoratorBlob={true}
            decoratorBlobCss={tw`xl:w-80 xl:h-80 opacity-10 `}
          />




          <ThreeColSimpleWithImage blogPosts={blogPosts} />

          <GetStartedLight pushDownFooter={false} subheading="Are you ready?" heading="Grow your practice to next level of Digital Experience with Dontrix"
            primaryLinkUrl={"https://calendly.com/dontrix/dontrix-demo"} secondaryLinkUrl="/web/contact" />


          <Footer />
        </Suspense>
      </AnimationRevealPage>

    </>

  );
}
