import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import LogoImage from "images/logo.svg";
import { ReactComponent as YoutubeIcon } from "images/youtube-icon.svg";
import React, { useState,useCallback,useEffect } from "react";
// import ReCAPTCHA from "react-google-recaptcha";
// import Recaptcha from 'react-google-invisible-recaptcha';
import styled from "styled-components";
import tw from "twin.macro";

import {
  GoogleReCaptchaProvider,
  withGoogleReCaptcha
} from "react-google-recaptcha-v3";

const Container = tw.div`relative bg-gray-200 text-gray-700 -mb-8 -mx-8 px-8 py-20 lg:py-24`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const SixColumns = tw.div`flex flex-wrap text-center sm:text-left justify-center sm:justify-start md:justify-between -mt-12`;

const Column = tw.div`px-4 sm:px-0 sm:w-1/4 md:w-auto mt-12`;

const ColumnHeading = tw.h5`uppercase font-bold`;

const LinkList = tw.ul`mt-6 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:border-gray-700 pb-1 transition duration-300`;

const SubscribeNewsletterColumn = tw(Column)`text-center lg:text-left w-full! lg:w-auto! mt-20 lg:mt-12`;
const SubscribeNewsletterContainer = tw.div`max-w-sm mx-auto lg:mx-0 `;
const SubscribeText = tw.p`mt-2 lg:mt-6 text-sm font-medium text-gray-600`;
const SubscribeForm = tw.form`mt-4 lg:mt-6 text-sm sm:flex max-w-xs sm:max-w-none mx-auto sm:mx-0`;
const Input = tw.input`bg-gray-300 px-6 py-3 rounded sm:rounded-r-none border-2 sm:border-r-0 border-gray-400 hover:border-primary-500 focus:outline-none transition duration-300 w-full`;
const SubscribeButton = tw(PrimaryButtonBase)`mt-4 sm:mt-0 w-full sm:w-auto rounded sm:rounded-l-none px-8 py-3`;

const Divider = tw.div`my-16 border-b-2 border-gray-300 w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-48`;
// const LogoText = tw.h5`ml-2 text-xl font-black tracking-wider text-gray-800`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-500`;

const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-900 text-gray-100 hover:bg-gray-700 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const MyApp =({googleReCaptchaProps}) => {

  const refRecaptcha = React.useRef(null)
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [recap, setRecap] = useState('');
  const [token, setToken] = useState(null);
  function ValidateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return (true)
    }

    return (false)
  }
  function handleSave() {
    if (email && ValidateEmail(email)) {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ emails: email ,g_re_cap:token})
      };
      fetch(process.env.REACT_APP_API_URL+ '/leads/', requestOptions)
        .then(response => {
         
          response.ok ? setMessage("Thanks for subscribing,we will update you with important product updates and blogs related to improve your practice.") :
            setError("Error while subscribing")
        })
        .catch(error => { setError("Error while subscribing"); });
    } else {
      setError("Enter valied email");
    }

  }
  //   useEffect(() => {
  //     // POST request using fetch inside useEffect React hook


  // // empty dependency array means this effect will only run once (like componentDidMount in classes)
  // }, []);
 

  const getToken = useCallback(async () => {
    const _token = await googleReCaptchaProps.executeRecaptcha("footer");
    setToken(_token);
  }, [googleReCaptchaProps]);

  useEffect(() => {
    getToken();
  }, [getToken]);

 
  return (
    <Container>
      <Content>
        <SixColumns>
          <Column>
            <ColumnHeading>Doctor's Features</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/web/fdr#emr">Electronic Medical Records</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/web/fdr#chart">Smart Charting</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/web/fdr#gallery">Image Gallery</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/web/fdr#timeline">Treatment Timeline</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/web/fdr#analytics">Clinic Analytics</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/web/fdr#epresc">E-Prescription</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Front-Office Features</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/web/ffo#checkin">Easy Check-In</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/web/ffo#shceduling">Smart Scheduling</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/web/ffo#labwork">Lab-Work Tracker</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/web/ffo#followup">Followup Tracker</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/web/ffo#pharma">Pharmacy Management</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/web/ffo#sms">Patient Communication</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Clinic-Manager Features</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/web/fcm#expense">Expense Management </Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/web/fcm#staff">Staff Management </Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/web/fcm#inventory">Inventory Management</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/web/fcm#report">Custom Reporting</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/web/fcm#multiclinic">Multi-Location Management</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/web/fcm#pportal">Clinic Custom Portal</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Main</ColumnHeading>
            <LinkList>
            <LinkListItem>
                <Link href="/web/pricing">Pricing</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/web/videos">Video Blog</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="https://blog.dontrix.com">Blog</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/web/faq">FAQs</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/web/contact">Contact Us</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/web/about">About Us</Link>
              </LinkListItem>
            </LinkList>
          </Column>

          <Column>
            <ColumnHeading>Legal</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/web/privacypolicy">Privacy Policy</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/web/tos">Terms of Service</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/web/disclaimer">Disclaimer</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <SubscribeNewsletterColumn>
            <SubscribeNewsletterContainer>
              <ColumnHeading>Subscribe to our Newsletter</ColumnHeading>
              <SubscribeText>
                We deliver high quality blog posts written by professionals specifically on improving dental practice efficiency. And we promise no spam.
              </SubscribeText>
              <SubscribeForm method="get" action="#">
              <div className="mt-5 text-center"> <Input type="email" id='email' onChange={e => setEmail(e.target.value)} placeholder="Your Email Address" /> </div>
               
                  <div className="mt-5 text-center"><SubscribeButton type="button"  onClick={handleSave}>Subscribe</SubscribeButton> </div>
              </SubscribeForm>
              {message ? <span style={{ "color": "green" }}>{message}</span> : error && <span style={{ "color": "red" }}>{error}</span>}
              <div className="mt-5 text-center">
                 
                  {/* <Recaptcha
  ref={refRecaptcha}
  sitekey="6LeDZ6UgAAAAAMos3qVej02qlz_BCj_tTTzIND6t"
  onResolved={onResolved } /> */}
                  </div>
            </SubscribeNewsletterContainer>
          </SubscribeNewsletterColumn>
        </SixColumns>
        <Divider />
        <ThreeColRow>
          <LogoContainer>
            <LogoImg src={LogoImage} alt="Dontrix cloud dental software"/>

          </LogoContainer>
          <CopywrightNotice>&copy; 2022 ARKIT SYSTEMS. All Rights Reserved. MSME REGNO. TN-21-0012825</CopywrightNotice>
          <SocialLinksContainer>
            <SocialLink href="https://www.facebook.com/DontrixCloudPracticeManagement">
              <FacebookIcon />
            </SocialLink>

            <SocialLink href="https://www.youtube.com/channel/UC9bAjKafxM-HFirf59cZ6HA">
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer>
        
        </ThreeColRow>
      </Content>
    </Container>
  );
};
const MyAppp = withGoogleReCaptcha(MyApp);

export default function App() {
  return (
   
      <MyAppp />
   
  );
}