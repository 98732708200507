
// import AboutPage from "pages/AboutPage";
// import ContactPage from "pages/ContactPage";
// import DashboardPage from 'pages/DashboardPage.js';
// import Login from "pages/LoginPage.js";
// import Register from "pages/RegisterPage.js";
// import PrivacyPolicy from "pages/PrivacyPolicy";
// import BookDemoPage from "pages/BookDemo";

import { lazy } from "react";

// import VerifyEmail from "pages/VerifyEmail";
const PageNotFound      = lazy(() => import( "components/cards/PageNotFound"));
const ffo               = lazy(() => import( "parts/FeaturesFrontOffice"));
const fdr               = lazy(() => import( "parts/FeaturesDoctor"));
const fcm               = lazy(() => import( "parts/FeaturesClinicManager"));
const About             = lazy(() => import( "parts/About"));
const FAQ               = lazy(() => import( "parts/FAQ"));
const Contact           = lazy(() => import( "parts/Contact"));
const BlogIndex         = lazy(() => import( "parts/BlogIndex"));
const DashboardPage     = lazy(() => import( "./pages/DashboardPage"));
const LoginPage         = lazy(() => import( "./pages/LoginPage"));
const RegisterPage      = lazy(() => import( "./pages/RegisterPage"));
const VediosPage      = lazy(() => import( "./pages/Vedios"));

const benifitsofdontrix = lazy(() => import( "parts/blogs/benifits_of_dontrix"));
const benifitsofcloud   = lazy(() => import( "parts/blogs/benifits_of_cloud"));
const increaserevenue   = lazy(() => import( "parts/blogs/increase_revenue"));
const Pricing           = lazy(() => import( "parts/Pricing"));
const Privacy           = lazy(() => import( "parts/PrivacyPolicy"));
const Disclaimer           = lazy(() => import( "parts/Disclaimer"));
const Tos           = lazy(() => import( "parts/Toc"));
const routes = [

  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-diamond text-red",
    component: DashboardPage,
    layout: "/vd",
  },
  {
    path: "/pricing",
    component: Pricing,
    layout: "/web", redirect: true
  }, 
  {
    path: "/videos",
    component: VediosPage,
    layout: "/web", redirect: true
  }, 
  {
    path: "/ffo",
    component: ffo,
    layout: "/web", redirect: true
  }, 
  {
    path: "/fdr",
    component: fdr,
    layout: "/web", redirect: true
  }, 
  {
    path: "/fcm",
    component: fcm,
    layout: "/web", redirect: true
  }, {
    path: "/blogs",
    component: BlogIndex,
    layout: "/web", redirect: true
  },
  {
    path: "/pnf",
    component: PageNotFound,
    layout: "/web", redirect: true
  },,
  {
    path: "/about",
    component: About,
    layout: "/web", redirect: true
  },,
  {
    path: "/faq",
    component: FAQ,
    layout: "/web", redirect: true
  },,
  {
    path: "/contact",
    component: Contact,
    layout: "/web", redirect: true
  },
  {
    path: "/benefits-of-dontrix",
    component: benifitsofdontrix,
    layout: "/web", redirect: true
  }, 
  {
    path: "/benefits-of-cloud",
    component: benifitsofcloud,
    layout: "/web", redirect: true
  }, 
  {
    path: "/security",
    component: increaserevenue,
    layout: "/web", redirect: true
  }, 
  {
    path: "/privacypolicy",
    component: Privacy,
    layout: "/web", redirect: true
  }, 
  {
    path: "/disclaimer",
    component: Disclaimer,
    layout: "/web", redirect: true
  }, 
  {
    path: "/tos",
    component: Tos,
    layout: "/web", redirect: true
  }, 
  // {
  //   path: "/changepass",
  //   component: ChangePassword,
  //   layout: "/auth", redirect: true
  // }, {
  //   path: "/verify",
  //   component: VerifyEmail,
  //   layout: "/auth", redirect: true
  // },
  
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-atom",
    component: LoginPage,
    layout: "/auth", redirect: true
  },

  {
    path: "/register",
    name: "Register",
    icon: "ni ni-atom",
    component: RegisterPage,
    layout: "/auth", redirect: true
  },

];

export default routes;
