import React from "react";
import ReactDOM from "react-dom";

import AppT from "./AppT";

import {
  GoogleReCaptchaProvider,
  withGoogleReCaptcha
} from "react-google-recaptcha-v3";
const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
     <GoogleReCaptchaProvider reCaptchaKey="6LeDZ6UgAAAAAMos3qVej02qlz_BCj_tTTzIND6t">
   
    <AppT />

    </GoogleReCaptchaProvider>
  </React.StrictMode>,
  rootElement
);

