import React from 'react'
import tw from "twin.macro";
import PageNotImage from "images/pnf.png"
import {Container as ContainerBase } from "components/misc/Layouts.js"

const Image = tw.img`max-w-full w-96 rounded-t sm:rounded relative z-20`;
const Row = tw.div`flex items-center justify-center flex-col lg:flex-row px-8`
const Container = tw(ContainerBase)` -mx-10`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

export default function PageNotFound({
    imageSrc = "https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
}) {
  return (
    <div>  <Container>
    <Content> <Row><Image src={PageNotImage} /> </Row></Content></Container>
      </div>
  )
}
