import React, { Suspense } from "react";
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { store } from './app/store';
import './style.css';
import './output.css';
import './index.css';
import WebLayout from './layouts/WebLayout';
import VizDatAppLayout from './layouts/AppLayout';
import HomePage from './pages/HomePage';
import PrivateRouter from './router/PrivateRouter';
import "tailwindcss/base.css";
import "styles/globalStyles.css";
import { css } from "styled-components/macro"; //eslint-disable-line



export default function AppT() {
  return (
    
    <Provider store={store}>

        <BrowserRouter >
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route path="/" exact render={(props) => <HomePage {...props} />} />
            <Route path="/web" render={(props) => <WebLayout {...props} />} />
            <PrivateRouter path="/vd" component={VizDatAppLayout} />
          </Switch>
          </Suspense>
        </BrowserRouter>     </Provider>
  )
}

