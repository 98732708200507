
import React from "react";
// react library for routing
import { Redirect, Route, Switch, useLocation } from "react-router-dom";


import routes from "../routes";
import WebNavbar from 'parts/WebNavbar'

import WebFooter from "components/footers/FiveColumnWithInputForm.js";
import PageNotFound from "components/cards/PageNotFound";
function Auth() {
  const location = useLocation();
  const mainContentRef = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
    document.body.classList.add("bg-default");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.remove("bg-default");
    };
  });
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/web") {
        
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        
        
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="main-content" ref={mainContentRef}>
        <WebNavbar />
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/web/pnf" />
        </Switch>
      </div>
      <WebFooter />
    </>
  );
}

export default Auth;
