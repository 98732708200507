// @flow
import React, { Suspense } from 'react';
import { Redirect, Route } from "react-router-dom";

function PrivateRoute({ component: Component, ...rest }) {
 return (
      <>
         {
           
               <Route exact
                  {...rest}
                  render={
                     props => localStorage.getItem("userInfo") ? 
                     (  <Suspense fallback={<div>Loading Private Component ...</div>}><Component {...props} /></Suspense>)  : 
                     (<Redirect to={{ pathname: "/auth/login" }} />)
                  }
               />
         }
      </>


   )
};

export default PrivateRoute;


